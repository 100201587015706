.login-form {
    padding-top:10em;
    padding-bottom: 10em;
}

.redbutton {
    margin-top: 1em !important ;
}

.loginModal {
    position: static !important;
}

.logingrid {
    margin-left: 20% !important;
    margin-right: 20% !important;
}

@media only screen and (max-width: 600px) {
    h1.ui.header {
        font-size: 1.2rem !important;
      }
    h2.ui.header {
        font-size: 1rem !important;
      }

    .logingrid {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }

    .ui.large.button {
        font-size: 0.8rem !important;
    }
}