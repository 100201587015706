body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.corpHeader {
    margin: auto !important;
    width: 30%;
vertical-align: middle !important ;
margin-right: 10em !important;
margin-left: 0.5em !important;
display: -webkit-inline-box !important;
display: -ms-inline-grid !important;
}

.uName {
    margin: auto;
    width: 40%;
}

.menuItem  {
    font-size: 0.6em !important;
}

.menuIcon .icon {
    font-size: 1.75em;
    margin: 0 auto .5rem!important
}

.headertxt {
   margin: auto;
}

@media only screen and (max-width: 600px) {
    .corpHeader {
        margin: auto !important;
        width: 100%;
    vertical-align: middle !important ;
    margin-right: 10em !important;
    margin-left: 0.5em !important;
    display: -webkit-inline-box !important;
    display: -ms-inline-grid !important;
    }

}
.login-form {
    padding-top:10em;
    padding-bottom: 10em;
}

.redbutton {
    margin-top: 1em !important ;
}

.loginModal {
    position: static !important;
}

.logingrid {
    margin-left: 20% !important;
    margin-right: 20% !important;
}

@media only screen and (max-width: 600px) {
    h1.ui.header {
        font-size: 1.2rem !important;
      }
    h2.ui.header {
        font-size: 1rem !important;
      }

    .logingrid {
        margin-left: 5% !important;
        margin-right: 5% !important;
    }

    .ui.large.button {
        font-size: 0.8rem !important;
    }
}
.spacer {
    height: 80px;
}

.exchangeicon {
    padding-left: 5px; 
}

.popupCls {
    display: flex;
}

.headerGrid {
    width: 100% !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.ui.small.table {
    font-size: .65em !important;
}

.download {
    cursor: pointer;
}

.pagi {
    float:right;
    font-size: 0.8em !important;
}

.size {
    font-size: 0.8em !important;

}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child
{
    border-left: 1px solid rgba(34,36,38,.1) !important;
}

.search-button {
    height: 30% !important;
    margin-top: auto !important;
}

.loginTag {
    border : 0px !important;
}

.sizetag {
    float: right;
    margin-top: 5px !important;
}

.ui.small.table.resultView {
    font-size : 0.6em !important;
}

.resultContainer {
    overflow: auto;
}

.resultView {
    min-width: 1000px;
}


@media only screen and (max-width: 600px) {
    h3.ui.header {
        font-size: 0.8rem !important;
      }

    .sizetag {
        float:right;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 0.7rem !important;
    }

    .pagi {
        float:right;
        font-size: 0.8em !important;
    }

}
.descLbl {
    margin-top : 0.25em !important;
    margin-right: 1px !important;
}

.areaLbl {
    margin-top : 0.25em !important;
    margin-right: 1px !important;
 }
.flatpickr-calendar,
.flatpickr-calendar > div {
    height: auto;
}

.receiveForm {
    text-align: left;
}

.deleteButton {
    height:40%; 
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.userName {
    opacity: 1 !important;
}
.userName > label{
    opacity: 1 !important;
}

.userName > .input{
    opacity: 1 !important;
    font-size: 150% !important;
}

.userButton{
margin-top: 30% !important;
margin-bottom: 30% !important;
}
    .copyButton {
        height:40%; 
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        }
    

.buttongrp{
    display: block;
    height: 80%;
    margin-top: auto !important;
    margin-bottom: 0px !important;
}

.labelInput > .input {
    font-size: 80% !important;
    height: 50%;
    margin-top: auto !important;
    margin-bottom: auto !important;
    opacity: 1 !important;
}

.labelInput {
    opacity:1 !important;
}

td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.actionArea {
    display: flex;
}

.formButton .button {
    margin-top: 30% !important;
}

.mainseg {
    box-shadow: none !important;
    border: none !important;
}

.minusicon {margin-left: 0.3em !important;

}

.ui.small.table {
    font-size : 0.8em !important;
}

.selectCell {
    font-size : 0.7em !important;
}

.dlbutton {
    font-size: 0.5em !important;
    margin-left: 1em !important;
}
.postext 
{
    color: red;
}

.negtext 
{
    color: green;
}
