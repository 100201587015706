.spacer {
    height: 80px;
}

.exchangeicon {
    padding-left: 5px; 
}

.popupCls {
    display: flex;
}

.headerGrid {
    width: 100% !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}

.ui.small.table {
    font-size: .65em !important;
}

.download {
    cursor: pointer;
}

.pagi {
    float:right;
    font-size: 0.8em !important;
}

.size {
    font-size: 0.8em !important;

}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child
{
    border-left: 1px solid rgba(34,36,38,.1) !important;
}

.search-button {
    height: 30% !important;
    margin-top: auto !important;
}

.loginTag {
    border : 0px !important;
}

.sizetag {
    float: right;
    margin-top: 5px !important;
}

.ui.small.table.resultView {
    font-size : 0.6em !important;
}

.resultContainer {
    overflow: auto;
}

.resultView {
    min-width: 1000px;
}


@media only screen and (max-width: 600px) {
    h3.ui.header {
        font-size: 0.8rem !important;
      }

    .sizetag {
        float:right;
        margin-top: auto;
        margin-bottom: auto;
        font-size: 0.7rem !important;
    }

    .pagi {
        float:right;
        font-size: 0.8em !important;
    }

}