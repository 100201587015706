
.corpHeader {
    margin: auto !important;
    width: 30%;
vertical-align: middle !important ;
margin-right: 10em !important;
margin-left: 0.5em !important;
display: -webkit-inline-box !important;
display: -ms-inline-grid !important;
}

.uName {
    margin: auto;
    width: 40%;
}

.menuItem  {
    font-size: 0.6em !important;
}

.menuIcon .icon {
    font-size: 1.75em;
    margin: 0 auto .5rem!important
}

.headertxt {
   margin: auto;
}

@media only screen and (max-width: 600px) {
    .corpHeader {
        margin: auto !important;
        width: 100%;
    vertical-align: middle !important ;
    margin-right: 10em !important;
    margin-left: 0.5em !important;
    display: -webkit-inline-box !important;
    display: -ms-inline-grid !important;
    }

}